import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit, BiTrash, BiPlay, BiStop, BiCopy } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Live from '../../models/lives';
import {
  getAllLives,
  getLive as getLiveService,
  startLive as startLiveService,
  stopLive as stopLiveService,
  deleteLive,
} from '../../services/lives';

import { Container } from './style';

const Lives: React.FC = () => {
  const history = useHistory();

  const [lives, setLives] = useState([] as Live[]);

  const getErrorMessage = (error: any) => {
    return (
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message
    );
  };

  const getLives = async () => {
    const allLives = await getAllLives();
    if (allLives) {
      setLives(allLives);
    }
  };

  const startLive = async (liveId: string) => {
    try {
      await startLiveService(liveId);
      Swal.fire({ icon: 'success', text: 'Live iniciada!' });
      await getLives();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao iniciar live. ' + errorMessage,
      });
    }
  };

  const stopLive = async (liveId: string) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja parar esta live?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await stopLiveService(liveId);
          Swal.fire({
            icon: 'success',
            text: 'Live parada com sucesso!',
          });
          await getLives();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Houve um erro ao parar a live. ' + errorMessage,
          });
        }
      }
    });
  };

  const copyRmtp = async (liveId: string) => {
    const actualLive = await getLiveService(liveId);
    const rtmp = actualLive.rtmp;

    const actualDocument = document.getElementById('lives-container');

    const rtmpInput = document.createElement('input');
    rtmpInput.value = rtmp;
    actualDocument?.appendChild(rtmpInput);
    rtmpInput.select();
    rtmpInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    rtmpInput.remove();
    Swal.fire({ text: `RTMP "${rtmp}" copiado!`, icon: 'info' });
  };

  const editLive = useCallback(
    (liveId: any) => {
      history.push(`edit-live/${liveId}`);
    },
    [history],
  );

  const removeLive = useCallback(async (liveId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta Live?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteLive(liveId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Live excluida com sucesso!',
          });

          await getLives();
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir Live',
            text: e.message,
          });
        }
      }
    });
  }, []);

  const createLive = async () => {
    history.push('create-live');
  };

  const livesToBeShown = useMemo(() => {
    return lives && lives.length
      ? lives.map(live => ({
          id: live.id,
          name: live.name,
          description: live.description,
          start: live.start,
          stop: live.stop,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                title="Editar Live"
                className="small info"
                onClick={() => editLive(live.id)}
                style={{ margin: '0 2px' }}
              >
                <BiEdit />
              </DefaultButton>

              {live.status !== 'ACTIVE' && live.status !== 'STOPPED' && (
                <DefaultButton
                  title="Iniciar Live"
                  className="small success"
                  onClick={() => startLive(live.id)}
                  style={{ margin: '0 2px' }}
                >
                  <BiPlay />
                </DefaultButton>
              )}

              {live.status === 'ACTIVE' && (
                <DefaultButton
                  title="Parar Live"
                  className="small warning"
                  style={{ color: 'white', margin: '0 2px' }}
                  onClick={() => stopLive(live.id)}
                >
                  <BiStop />
                </DefaultButton>
              )}

              <DefaultButton
                onClick={() => removeLive(live.id)}
                className="small danger"
                title="Excluir Live"
                style={{ margin: '0 2px' }}
              >
                <BiTrash />
              </DefaultButton>

              {live.status === 'ACTIVE' && (
                <DefaultButton
                  onClick={() => copyRmtp(live.id)}
                  className="small danger"
                  title="Copiar RMTP"
                  style={{ backgroundColor: 'gray', margin: '0 2px' }}
                >
                  <BiCopy />
                </DefaultButton>
              )}
            </div>
          ),
        }))
      : [];
  }, [lives, editLive, removeLive]);

  useEffect(() => {
    getLives();
  }, []);

  return (
    <Container id="lives-container">
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Lives</span>]}
      />
      <DefaultPageTitle>Lives</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createLive}>Criar Live</DefaultButton>
      </CreateButtonDefaultContainer>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Início</span>,
            propName: 'start',
          },
          {
            headerLabel: <span>Fim</span>,
            propName: 'stop',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={livesToBeShown}
        emptyListMessage={'Não foram encontradas Lives cadastradas!'}
      />
    </Container>
  );
};

export default Lives;
