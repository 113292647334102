import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { getAllContents } from '../../services/contents';
import { getAllCourses as getAllTrails } from '../../services/trails';
import { ExamsReportContainer } from './styles';
import { generateExamsReport } from '../../services/reports';
import { getAllUsers } from '../../services/users';
import { getAllCourses } from '../../services/courses';

const ExamsReport: React.FC = () => {
  const [typeOfSearch, setTypeOfSeach] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedTrails, setSelectedTrails] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const types = ['Curso', 'Trilha', 'Módulo'];
  const [courses, setCourses] = useState([]);
  const [trails, setTrails] = useState([]);
  const [modules, setModules] = useState([]);
  const [users, setUsers] = useState([]);

  const getFilterItems = async () => {
    const localUsers = await getAllUsers();
    setUsers(
      (localUsers || []).map((u: any) => ({
        value: u.user_id,
        label: `${u.name} - ${u.registration}`,
      })) as any,
    );

    const localCourses = await getAllContents({ is_library: true });
    setCourses(
      (localCourses || []).map((c: any) => ({
        value: c.id,
        label: c.title,
      })) as any,
    );

    const localTrails = await getAllTrails();
    setTrails(
      (localTrails || []).map((t: any) => ({
        value: t.trail_id,
        label: t.name,
      })),
    );

    const localModules = await getAllCourses();
    setModules(
      (localModules || []).map((m: any) => ({
        value: m.course_id,
        label: m.title,
      })),
    );
  };

  const generateReport = async (reportType: string) => {
    let type;

    if (typeOfSearch === 'Curso') {
      type = 'course';
    } else if (typeOfSearch === 'Trilha') {
      type = 'trail';
    } else if (typeOfSearch === 'Módulo') {
      type = 'module';
    } else {
      type = undefined;
    }

    const queryStrings: any = {
      user_id: selectedUsers,
      reference_id:
        typeOfSearch === 'Curso'
          ? selectedCourses
          : typeOfSearch === 'Trilha'
          ? selectedTrails
          : selectedModules,
      reference_type: type,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateExamsReport(reportType, queryStrings);
  };

  useEffect(() => {
    getFilterItems();
  }, []);

  const changeUserOption = useCallback((options: any) => {
    if (options && options.length) {
      setSelectedUsers(options.map((o: any) => o.value));
    } else {
      setSelectedUsers([]);
    }
  }, []);

  const changeCousesOptions = useCallback((options: any) => {
    if (options && options.length) {
      setSelectedCourses(options.map((o: any) => o.value));
    } else {
      setSelectedCourses([]);
    }
  }, []);

  const changeTrailOptions = useCallback((options: any) => {
    if (options && options.length) {
      setSelectedTrails(options.map((o: any) => o.value));
    } else {
      setSelectedTrails([]);
    }
  }, []);

  const changeModuleOptions = useCallback((options: any) => {
    if (options && options.length) {
      setSelectedModules(options.map((o: any) => o.value));
    } else {
      setSelectedModules([]);
    }
  }, []);

  const changeTypeSearch = useCallback((option: any) => {
    if (option) {
      setTypeOfSeach(option.value);
    } else {
      setTypeOfSeach('');
    }
  }, []);

  return (
    <ExamsReportContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Relatórios</span>,
          <span>Provas</span>,
        ]}
      />

      <DefaultPageTitle>Relatório de Provas</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="type">Usuário</label>
          <Select
            placeholder="Selecione um usuário"
            isMulti
            onChange={(options: any) => changeUserOption(options)}
            options={users}
          />
        </span>

        <span className="entire-row" style={{ position: 'relative' }}>
          <label htmlFor="type">Tipo de busca</label>
          <Select
            placeholder="Selecione um tipo de busca"
            onChange={(option: any) => changeTypeSearch(option)}
            options={types.map(t => ({ value: t, label: t }))}
            isClearable
          />
        </span>

        {(() => {
          switch (typeOfSearch) {
            case 'Curso':
              return (
                <span key="curso" className="entire-row">
                  <label htmlFor="course">Curso</label>
                  <Select
                    placeholder="Selecione um curso"
                    isMulti
                    onChange={(options: any) => changeCousesOptions(options)}
                    options={courses}
                  />
                </span>
              );

            case 'Trilha':
              return (
                <span key="trilha" className="entire-row">
                  <label htmlFor="trail">Trilha</label>
                  <Select
                    placeholder="Selecione uma trilha"
                    isMulti
                    onChange={(options: any) => changeTrailOptions(options)}
                    options={trails}
                  />
                </span>
              );
            case 'Módulo':
              return (
                <span key="modulo" className="entire-row">
                  <label htmlFor="module">Módulo</label>
                  <Select
                    placeholder="Selecione um módulo"
                    isMulti
                    onChange={(options: any) => changeModuleOptions(options)}
                    options={modules}
                  />
                </span>
              );
          }
        })()}

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport('pdf')}
            className="success"
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>
          <DefaultButton
            onClick={() => generateReport('csv')}
            className="success"
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </ExamsReportContainer>
  );
};

export default ExamsReport;
