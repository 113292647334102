import convertToLesson from "../helpers/converters/convert-lesson";
import httpClient from "../http-client";
import Lesson from "../models/lesson";
import LessonFromReponse from "../models/from-api-response/lesson";
import { uploadFile } from "./files";
import LessonForCreate from "../models/for-create/lesson";
import LessonForUpdate from "../models/for-update/lesson";

const getLesson = async (lessonId: string) => {
  const lesson = (
    await httpClient.get<LessonFromReponse>(`courses/${lessonId}`)
  ).data;

  let convertedLesson = {} as Lesson;
  if (lesson && Object.keys(lesson).length) {
    convertedLesson = convertToLesson(lesson);
  }

  return convertedLesson;
};

const getAllLessons = async () => {
  const lessons = (await httpClient.get<LessonFromReponse[]>("courses")).data;

  let convertedLessons = [] as Lesson[];
  if (lessons && lessons.length) {
    convertedLessons = lessons.map(convertToLesson);
  }

  return convertedLessons;
};

const deleteLesson = async (lessonId: string) => {
  await httpClient.delete(`courses/${lessonId}`);
};

const createLesson = async (newLesson: LessonForCreate) => {
  const createLessonResponse = (
    await httpClient.post<LessonFromReponse>(`courses/`, newLesson)
  ).data;

  return createLessonResponse;
};

const updateLesson = async (lessonId: string, newLesson: LessonForUpdate) => {
  await httpClient.put(
    `courses/${lessonId}`,
    newLesson
  );
};

const changeLessonThumbnail = async (lessonId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`courses/${lessonId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const activateOrInactivateLesson = async (
  lessonId: string,
  activate: boolean
) => {
  await httpClient.put(
    `courses/${lessonId}/active`,
    { is_active: activate }
  );
};

export {
  getAllLessons,
  deleteLesson,
  getLesson,
  createLesson,
  updateLesson,
  changeLessonThumbnail,
  activateOrInactivateLesson,
};
