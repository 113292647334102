import axios from 'axios';
import MediaFromResponse from '../models/from-api-response/media';

const sambaVideosApiBaseUrl = 'https://sambavideos.sambatech.com/v1';

const getMedias = async (filters?: object) => {
  const medias = (
    await axios.get<MediaFromResponse[]>(
      `${sambaVideosApiBaseUrl}/medias?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        params: filters,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    )
  ).data;

  return medias;
};

export { getMedias };
