import styled from "styled-components";

const AboutUsContainer = styled.div``;

const ImageUploadContainer = styled.div`
  width: 100%;
  border: solid 1px var(--default-dark-gray);
  border-radius: 5px;
  padding: 15px;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BannerImage = styled.img`
  width: 550px;
  margin-bottom: 15px;
`;

export { AboutUsContainer, ImageUploadContainer, BannerImage };
