import convertToLive from '../helpers/converters/convert-live';
import httpClient from '../http-client';
import LiveForCreate from '../models/for-create/lives';
import LiveForUpdate from '../models/for-update/lives';
import LiveFromResponse from '../models/from-api-response/lives';
import Live from '../models/lives';

const getAllLives = async () => {
  const result = (await httpClient.get<LiveFromResponse[]>('/lives')).data;

  let convertedList = [] as Live[];
  if (result && result.length) {
    convertedList = result.map(res => convertToLive(res));
  }

  return convertedList;
}

const startLive = async (liveId: string) => {
  await httpClient.post(`/lives/start/${liveId}`);
}

const stopLive = async (liveId: string) => {
  await httpClient.post(`/lives/stop/${liveId}`);
}

const getLive = async (liveId: string) => {
  return (await httpClient.get<Live>(`/lives/${liveId}`)).data;
}

const createLive = async (newLive: LiveForCreate) => {
  await httpClient.post('/lives', newLive);
}

const deleteLive = async (liveId: string) => {
  await httpClient.delete(`/lives/${liveId}`);
}

const updateLive = async (liveId: string, newLive: LiveForUpdate) => {
  await httpClient.put(`/lives/${liveId}`, newLive);
}

export {
  getAllLives,
  startLive,
  stopLive,
  getLive,
  createLive,
  deleteLive,
  updateLive
}
