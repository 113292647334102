import LiveFromResponse from "../../models/from-api-response/lives";
import Live from "../../models/lives";

const convertToLive= (live: LiveFromResponse): Live => {
  return {
    id: live.live_id,
    name: live.name,
    description: live.description,
    status: live.status,
    transmission_id: live.transmission_id,
    thumbnail_url: live.thumbnail_url,
    start: live.start ? new Intl.DateTimeFormat('pt-BR', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(live.start)) : '',
    stop: live.stop ? new Intl.DateTimeFormat('pt-BR', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(live.stop)) : '',
    rtmp: live.rtmp || '',
  };
};

export default convertToLive;
