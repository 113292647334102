import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { UpdatedAtLabel } from '../../components/UpdatedAt';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { hideModal, showModal } from '../../helpers/modal';
import Lesson from '../../models/lesson';
import {
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditCourseContainer,
} from './style';
import SelectLesson from './components/SelectLesson';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
} from '../../services/courses';
import Swal from 'sweetalert2';
import checkEmptyString from '../../helpers/check-empty-string';
import Content from '../../models/content';
import convertContent from '../../converters/convert-content';
import Select from 'react-select';
import Exam from '../../models/exam';
import { getExams } from '../../services/exams';

interface CreateAndEditCourseProps {
  courseId: string;
}

const CreateAndEditCourse: React.FC = () => {
  const history = useHistory();
  const { courseId } = useParams<CreateAndEditCourseProps>();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLessons, setSelectedLessons] = useState([] as any[]);
  const [updatedAt, setUpdatedAt] = useState('');
  const [selectedExam, setSelectedExam] = useState(
    {} as { label: string; value: string },
  );
  const [exams, setExams] = useState([] as Exam[]);

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o módulo.');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o módulo.');
      }

      if (!selectedLessons || !selectedLessons.length) {
        throw new Error('Selecione um ou mais vídeos antes.');
      }

      await createCourseService({
        title: name,
        description: description,
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.id,
          position: index + 1,
        })),
        exam_id:
          selectedExam && selectedExam.value ? selectedExam.value : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Módulo criado com sucesso!',
        icon: 'success',
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o módulo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o módulo.');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o módulo.');
      }

      if (!selectedLessons || !selectedLessons.length) {
        throw new Error('Selecione um ou mais vídeos antes.');
      }

      await updateCourseService(courseId, {
        title: name,
        description: description,
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.id,
          position: index + 1,
        })),
        exam_id:
          selectedExam && selectedExam.value ? selectedExam.value : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Módulo editado com sucesso!',
        icon: 'success',
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o módulo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const examsToBeSelected = useMemo(() => {
    let examsToBeReturned = [
      { label: 'Selecione uma prova para este curso', value: '' },
    ] as {
      label: string;
      value: string;
    }[];

    if (exams && exams.length) {
      examsToBeReturned.push(
        ...exams.map(exam => ({
          label: exam.title,
          value: `${exam.id}`,
        })),
      );
    }

    return examsToBeReturned;
  }, [exams]);

  const selectLessons = () => {
    showModal(
      'Selecionar Vídeos',
      <SelectLesson
        onApplySelection={applyLessonsSelection}
        selectedLessons={[...selectedLessons]}
      />,
    );
  };

  const applyLessonsSelection = (localSelectedLessons: Content[]) => {
    hideModal();

    if (localSelectedLessons) {
      setSelectedLessons(localSelectedLessons);
    }
  };

  const reorderSelectedLessons = (lesson: Lesson, up: boolean) => {
    if (selectedLessons) {
      const localSelectedLessons = [...selectedLessons];
      const indexOfLesson = localSelectedLessons.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedLessons.length) {
        localSelectedLessons.splice(indexOfLesson, 1);
        if (up) {
          localSelectedLessons.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedLessons.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedLessons(localSelectedLessons);
    }
  };

  const getCourse = useCallback(async () => {
    const foundExams = await getExams({ quiz: true });
    setExams((foundExams || []).filter(exam => exam.enabled));

    if (courseId) {
      const course = await getCourseService(courseId);

      setExams((foundExams || []).filter(exam => exam.enabled));
      if (course && Object.keys(course).length) {
        setName(course.title);
        setDescription(course.description);

        if (course.updated_at) {
          let date = new Date(course.updated_at);
          setUpdatedAt(date.toLocaleString());
        }

        if (course.contents && course.contents.length) {
          const convertedCourses =
            course.contents && course.contents.length
              ? course.contents
                  .sort((a: any, b: any) => (a.position > b.position ? 1 : -1))
                  .map((course: Content) => {
                    return convertContent(course);
                  })
              : [];
          setSelectedLessons(convertedCourses);
        }
      }

      const courseExam =
        typeof course.exam_id === 'object'
          ? (foundExams || []).find(
              (exam: any) => exam.id === course.exam_id,
            ) || ({} as Exam)
          : (foundExams || []).find(
              (exam: any) => exam.id === course.exam_id,
            ) || ({} as Exam);

      if (courseExam) {
        setSelectedExam({ label: courseExam.title, value: courseExam.id });
      }
    }
  }, [courseId]);

  const goToCourses = () => {
    history.push('/trails/episodes');
  };

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const isEditting = useMemo(() => {
    if (courseId) {
      return true;
    }

    return false;
  }, [courseId]);

  return (
    <CreateAndEditCourseContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/trails/contents">Trilhas</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Módulo</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Módulo
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Título
          </label>
          <DefaultInput
            value={name}
            onChange={e => setName(e.target.value)}
            id="name"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="exam">Quiz</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={examsToBeSelected}
            value={selectedExam}
            onChange={option =>
              option &&
              setSelectedExam(() => ({
                label: option.label,
                value: option.value,
              }))
            }
            id="exam"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Arquivos/Vídeos
          </label>

          <LessonSelectionContainer>
            {selectedLessons && selectedLessons.length ? (
              <SortLessonsContainer>
                {selectedLessons.map((lesson, index) => (
                  <div key={lesson.id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <DefaultButton
                          type="button"
                          title="Subir Aula"
                          className="small white up"
                          onClick={() => reorderSelectedLessons(lesson, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedLessons.length - 1 ? (
                        <DefaultButton
                          type="button"
                          title="Descer Aula"
                          className="small white down"
                          onClick={() => reorderSelectedLessons(lesson, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="lesson-title">
                      {index + 1} - {lesson.title}
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={selectLessons}>
              Selecionar
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateCourse(e) : createCourse(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCourseContainer>
  );
};

export default CreateAndEditCourse;
