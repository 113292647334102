import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import convertSecondsToTimeText from '../../helpers/seconds-to-time-text';
import Course from '../../models/course';
import {
  deleteCourse,
  getAllCourses,
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from '../../services/courses';
import { CoursesContainer } from './style';

const Courses: React.FC = () => {
  const history = useHistory();

  const [courses, setCourses] = useState([] as Course[]);
  const [courseName, setCourseName] = useState('');

  const getCourses = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localCourses = await getAllCourses({ title: courseName });
    setCourses(localCourses);
  };

  const editCourse = useCallback(
    (courseId: string) => {
      history.push(`edit-course/${courseId}`);
    },
    [history],
  );

  const removeCourse = useCallback(async (courseId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este módulo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteCourse(courseId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Módulo excluído com sucesso!',
          });

          await getCourses();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir módulo',
            text: 'Esse módulo já está associado a uma trilha.',
          });
        }
      }
    });
  }, []);

  const createCourse = async () => {
    history.push('create-course');
  };

  const activateOrInactivateCourse = useCallback(
    async (courseId: string, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } este curso?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCourseService(courseId, activate);

            getCourses();
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: `Erro ao ${activate ? 'ativar' : 'inativar'} módulo`,
              text: 'Esse módulo já está associado a uma trilha.',
            });
          }
        }
      });
    },
    [],
  );

  const coursesToBeShown = useMemo(() => {
    return courses && courses.length
      ? courses.map(course => ({
          name: course.title,
          description: course.description,
          duration: convertSecondsToTimeText(course.duration),
          average: `${course.average}`,
          active: course.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editCourse(course.course_id)}
                className="small info"
                title="Editar Curso"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateCourse(
                    course.course_id,
                    !course.is_active,
                  )
                }
                className="small warning"
                title={(course.is_active ? 'Inativar' : 'Ativar') + ' Conteúdo'}
              >
                {course.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => removeCourse(course.course_id)}
                className="small danger"
                title="Excluir Curso"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [courses, editCourse, removeCourse, activateOrInactivateCourse]);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (!courseName) {
      getCourses();
    }
  }, [courseName]);

  return (
    <CoursesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Trilhas</span>,
          <span>Módulos</span>,
        ]}
      />

      <DefaultPageTitle>Módulos</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createCourse}>Criar Módulo</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={getCourses}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={courseName}
            onChange={e => setCourseName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
            order: (localUsers: any[], currentOrder: string) => {
              setCourses([
                ...courses.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('active') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.is_active ? 1 : -1;
                  } else {
                    return a.is_active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={coursesToBeShown}
        emptyListMessage={'Não foram encontrados módulos cadastrados!'}
      />
    </CoursesContainer>
  );
};

export default Courses;
