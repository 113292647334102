import React, { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
} from '../../components/DefaultCreationForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import { EditCommentsContainer } from './styles';
import {
  getComments as getCommentsService,
  deleteComment as deleteCommentService,
} from '../../services/forum-comments';
import { BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';

interface EditCommentsParams {
  questionId: string;
  categoryId: string;
}

const EditComments: React.FC = () => {
  const history = useHistory();
  const { questionId, categoryId } = useParams<EditCommentsParams>();
  const [comments, setComments] = useState([] as any);

  const goToQuestion = () => {
    history.push(`/forum/categories/${categoryId}/questions`);
  };

  const getComments = async () => {
    const localComments = await getCommentsService(questionId);
    setComments(localComments);
  };

  const deleteComment = async (commentId: string) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja remover este comentário?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteCommentService(commentId);

          await getComments();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Erro ao excluir comentário. ' + errorMessage,
          });
        }
      }
    });
  };

  const commentsToBeShown = useMemo(() => {
    const commentsAnswers = (
      (comments || []).map((c: any) => c.comment) || []
    ).flat();

    return [...(comments || []), ...(commentsAnswers || [])].map((c: any) => ({
      description: c.description,
      user: c.user && c.user.name,
      likes: (
        <span>
          <strong>Positivos:</strong> {(c.likes && c.likes.total_positive) || 0}{' '}
          / <strong>Negativos:</strong>{' '}
          {(c.likes && c.likes.total_negative) || 0}
        </span>
      ),
      createdAt: c.created_at
        ? new Intl.DateTimeFormat('pt-BR', {
            dateStyle: 'medium',
            timeStyle: 'medium',
          }).format(new Date(c.created_at))
        : '',
      updatedAt: c.updated_at
        ? new Intl.DateTimeFormat('pt-BR', {
            dateStyle: 'medium',
            timeStyle: 'medium',
          }).format(new Date(c.created_at))
        : '',
      actions: (
        <>
          <DefaultButton
            className="small danger"
            onClick={() => deleteComment(c.answer_id)}
          >
            <BiTrash size={18} />
          </DefaultButton>
        </>
      ),
    }));
  }, [comments]);

  useEffect(() => {
    getComments();
  }, []);

  return (
    <EditCommentsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Fórum</Link>,
          <span>Editar Comentários</span>,
        ]}
      />

      <DefaultPageTitle>Editar Comentários</DefaultPageTitle>

      <div>
        <DefaultTable
          items={commentsToBeShown}
          headersConfig={[
            {
              headerLabel: <span>Descrição</span>,
              propName: 'description',
              attributes: { style: { width: '35%' } },
            },
            {
              headerLabel: <span>Usuário</span>,
              propName: 'user',
            },
            {
              headerLabel: <span>Likes</span>,
              propName: 'likes',
            },
            {
              headerLabel: <span>Criado em</span>,
              propName: 'createdAt',
            },
            {
              headerLabel: <span>Atualizado em</span>,
              propName: 'updatedAt',
            },
            {
              headerLabel: <span>Ações</span>,
              propName: 'actions',
            },
          ]}
        />
      </div>

      <DefaultCreationForm>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToQuestion}
          >
            Voltar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </EditCommentsContainer>
  );
};

export default EditComments;
