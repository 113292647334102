import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CutImage from "../../components/CutImage";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import { uploadFile } from "../../services/files";
import { AboutUsContainer, BannerImage, ImageUploadContainer } from "./style";
import {
  updateAboutUs as updateAboutUsService,
  getAboutUs as getAboutUsService,
} from "../../services/about-us";
import AboutUsBannerFromResponse from "../../models/from-api-response/about-us-banner";
import Swal from "sweetalert2";

const AboutUs: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [imageToUpload, setImageToUpload] = useState<File>();

  const getAboutUs = async () => {
    const localAboutus = await getAboutUsService();
    if (localAboutus) {
      setTitle(localAboutus.title || "");
      setDescription(localAboutus.description || "");
      setImage(localAboutus.image || "");
    }
  };

  const selectImage = () => {
    showModal(
      "Selecionar Imagem",
      <CutImage aspect={30 / 23} onCutImage={onCutImage} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setImageToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImage(`${reader.result}`);

      hideModal();
    }
  };

  const updateAboutUs = async (event: React.FormEvent) => {
    event.preventDefault();

    let imageForProduct = image;
    if (imageToUpload) {
      const formData = new FormData();
      formData.append("file", imageToUpload);

      const uploadedFile = await uploadFile(formData);

      imageForProduct = uploadedFile.reference;
    }

    const newProduct = {
      image: imageForProduct,
      title: title,
      description: description,
    } as AboutUsBannerFromResponse;

    await updateAboutUsService(newProduct);
  };

  const removeChanges = () => {
    Swal.fire({
      title: "Atenção",
      text:
        "Esta ação irá remover todo o conteúdo do Sobre Nós, deseja continuar?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: "question",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateAboutUsService({} as AboutUsBannerFromResponse);

          await getAboutUs();
        } catch (error) {
          Swal.fire({
            title: "Erro",
            text: "Erro ao remover as alterações. " + error.message,
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {
    getAboutUs();
  }, []);

  return (
    <AboutUsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Sobre</span>,
          <span>Sobre Nós</span>,
        ]}
      />

      <DefaultPageTitle>Sobre Nós</DefaultPageTitle>

      <DefaultCreationForm onSubmit={updateAboutUs}>
        <DefaultCreationFormGroup>
          <label htmlFor="title" className="required">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <DefaultTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="image" className="required">
            Imagem
          </label>

          <ImageUploadContainer>
            <DefaultButton type="button" onClick={selectImage}>
              Selecionar Imagem
            </DefaultButton>

            {image && <BannerImage src={image} />}
          </ImageUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            onClick={removeChanges}
            type="button"
            className="danger"
          >
            Excluir
          </DefaultButton>
          <DefaultButton type="submit" className="info">
            Salvar Alterações
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </AboutUsContainer>
  );
};

export default AboutUs;
