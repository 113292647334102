import styled from "styled-components";

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  width: 400px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 240px;
    margin-bottom: 24px;
  }

  button {
    width: 100%;
    margin-top: 8px;
  }
`;

const LoginFormGroup = styled.div`
  margin-bottom: 10px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

export { LoginContainer, LoginForm, LoginFormGroup };
