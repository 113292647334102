import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Lesson from '../../models/lesson';
import {
  deleteLesson,
  getAllLessons,
  activateOrInactivateLesson as activateOrInactivateLessonService,
} from '../../services/lessons';
import { LessonsContainer } from './style';

const Lessons: React.FC = () => {
  const history = useHistory();

  const [lessons, setLessons] = useState([] as Lesson[]);

  const getLessons = async () => {
    const localLessons = await getAllLessons();
    if (localLessons && localLessons.length) {
      setLessons(localLessons);
    }
  };

  const editLesson = useCallback(
    (lessonId: string) => {
      history.push(`edit-lesson/${lessonId}`);
    },
    [history],
  );

  const removeLesson = useCallback(async (lessonId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta aula?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteLesson(lessonId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Aula excluida com sucesso!',
          });

          await getLessons();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir aula',
            text:
              error.response && error.response.status === 423
                ? 'Esta aula já está associada a um curso!'
                : error.message,
          });
        }
      }
    });
  }, []);

  const createLesson = async () => {
    history.push('create-lesson');
  };

  const activateOrInactivateLesson = useCallback(
    async (courseId: string, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } esta aula?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateLessonService(courseId, activate);

            getLessons();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} aula. ${
                error.response && error.response.status === 423
                  ? 'Esta aula já está associada a um curso!'
                  : error.message
              }`,
            });
          }
        }
      });
    },
    [],
  );

  const lessonsToBeShown = useMemo(() => {
    return lessons && lessons.length
      ? lessons.map(lesson => ({
          title: lesson.title,
          description: lesson.description,
          active: lesson.active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editLesson(lesson.id)}
                className="small info"
                title="Editar Aula"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateLesson(lesson.id, !lesson.active)
                }
                className="small warning"
                title={(lesson.active ? 'Inativar' : 'Ativar') + ' Aula'}
              >
                {lesson.active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => removeLesson(lesson.id)}
                className="small danger"
                title="Excluir Aula"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [lessons, editLesson, removeLesson, activateOrInactivateLesson]);

  useEffect(() => {
    getLessons();
  }, []);

  return (
    <LessonsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Aulas</span>]}
      />

      <DefaultPageTitle>Aulas</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createLesson}>Criar Aula</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
            order: (localUsers: any[], currentOrder: string) => {
              setLessons([
                ...lessons.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('active') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.active ? 1 : -1;
                  } else {
                    return a.active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={lessonsToBeShown}
        emptyListMessage={'Não foram encontradas aulas cadastradas!'}
      />
    </LessonsContainer>
  );
};

export default Lessons;
