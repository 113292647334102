import httpClient from '../http-client';

const getComments = async (questionId: string) => {
  return (
    await httpClient.get(`/forum/answers`, {
      params: { question_id: questionId },
    })
  ).data;
};

const deleteComment = async (commentId: string) => {
  await httpClient.delete(`/forum/answers/${commentId}`);
};

export { getComments, deleteComment };
